.single-article-text h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  
  .single-article-text p {
    font-size: 15px;
    line-height: 1.6;
    color: #051922;
    margin-bottom: 30px;
  }

  .single-artcile-bg {
    /* background-size: cover; */
    /* background-position: center; */
    /* background-color: #ddd; */
    /* border-radius: 5px; */
    /* margin-bottom: 20px; */
  }

  /* .single-artcile-bg {
    background-image: url(../img/latest-news/news-bg-3.jpg);
    height: 450px;
  } */