.navbar {
  background-color: white;
  padding: 5px 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; 
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: 'Cabin';
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  gap: 10px; /* Reduce the gap between items */
  margin: 0;
  padding: 0;
}

.logo img {
  height: 80px; /* Adjust the height as needed */
}

.nav-item {
  position: relative;
  color: #27456F;
}

.nav-sub-item {
  text-decoration: none;
  color: #27456F;
  padding: 10px 15px;
  display: block;
  text-align: center; /* Center the text in main menu */
}

.nav-sub-item:hover {
  color: #27456F; /* Change color on hover */
  border-bottom: 2px solid #27456F; /* Add border bottom on hover */
  font-weight: bold;
}

.nav-item .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px;
  border-radius: 4px;
  white-space: nowrap; /* Ensure dropdown does not wrap text */
}

.nav-item:hover .dropdown {
  display: block;
}

.dropdown-item {
  position: relative;
  text-align: left; /* Align the text to the left in dropdown items */
}

.dropdown-item > a {
  padding: 10px 15px;
  display: block;
  color: 27456F;
  text-decoration: none;
  white-space: nowrap;
}

.dropdown-item > a {
  padding: 10px 15px;
  display: block;
  color: 27456F;
  text-decoration: none;
  white-space: nowrap;
}

.dropdown-item > a:hover {
  color: #27456F;
  font-weight: bold;
}

.nav-link {
  color: #27456F;
  text-decoration: none;
  padding: 10px 5px; /* Adjust padding to reduce space */
  display: block;
}

.nav-link:hover {
  font-weight: bold;
  color: #27456F;
  font-size: 1.25rem;
}

.dropdown-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  line-height: 30px;
}

.dropdown-section {
  display: flex;
  justify-content: space-evenly;
  flex: 1 1 200px;
}

.dropdown-section ul li a:hover {
  font-weight: bold;
  color: #27456F;
} 

.submenu-image {
  width: 100%;
  max-width: 200px;
  height: 260px;
  margin-top: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 30px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.close-button:hover {
  color: red;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
}

.nav-list.open {
  display: flex;
}

@media (max-width: 1024px) {
  .submenu-image {
    width: 100%;
    max-width: 150px;
    height: 210px;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .navbar {
    display: inherit;
    justify-content: center; /* Space between logo and menu */
    /* padding: 10px 20px;  */
    background-color: white;
  }

  .nav-list {
    flex-direction: column;
    display: none;
  }

  .logo img {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .nav-link {
    padding: 10px 5px; /* Ensure this padding is consistent */
  }

  .submenu-image {
    width: 100%;
    max-width: 100px;
    height: 140px;
    margin-top: 10px;
    margin-right: 10px;
  }

  .close-button {
    top: 10px;
    right: 10px;
    font-size: 24px;
  }
}
