

.subcontainer {
  display: flex;
  justify-content: center;
  
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

@media (max-width: 1200px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr;
  }
}


.card {
  /* border: 1px solid #ddd; */
  margin-top: 15px;
  margin-bottom: 15px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

/* .card:hover {
  transform: scale(1.05);
} */

.card-img {
  width: 100%;
  height: auto;
}

.card-body {
  padding: 16px;
}

.card-title {
  font-size: 1.2em;
  margin: 0.5em 0;
  font-family: sans-serif;
}

.card-price {
  color: #888;
}
