#port {
  z-index: -10;
  height: 100vh;
  background-image: url('../assets/img/sustainability2.png');
  background-size: cover;
  background-position: center center;
  position: relative;
  margin-top: -50px;
}

#name {
  position: absolute;
  font-size: 80px;
  margin: 0;
  padding: 0;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  font-family: 'Cabin';
  white-space: nowrap;
}

.subContent {
    font-family: "Cabin";
    text-align: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    max-width: "50%";
    font-size: 30px;
}

.returnRepairContent {
  font-family: "Cabin";
  justify-content: center;
  max-width: "50%";
  font-size: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.contentLine1 { 
  font-size: 28px; 
  font-family: 'Cabin'; 
  line-height: 35px; 
  }

  .contentLine2 {  
    margin-top: 30px; 
    font-size: 28px; 
    font-family: 'Cabin'; 
    line-height: 35px; 
  }

@media (max-width: 768px) {
  #name {
    font-size: 45px;
  }

  .subContent {
    font-size: 20px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .returnRepairContent {
    font-size: 20px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .contentLine1 {
    margin-top: 20px;
    font-size: 20px;
  }

  .contentLine2 {  
    margin-bottom: 30px; 
    font-size: 20px; 
  }
}